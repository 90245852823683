var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CompHeader',{attrs:{"label":"Seleccione la temporada (de la consulta)"}}),_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{class:("d-flex align-center " + (active ? 'm-gradient-primary' : '')),attrs:{"color":active ? 'white' : '',"dark":"","height":"200"},on:{"click":toggle}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"max-height":"200px","src":_vm.getimagen('copo-de-nieve.svg')}},[_c('v-card-title',[_vm._v("Invierno")])],1),_c('v-scroll-y-transition',[(active)?_c('div',{staticClass:"display-3 flex-grow-1 text-center"},[_vm._v("I")]):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{class:("d-flex align-center " + (active ? 'm-gradient-primary' : '')),attrs:{"color":active ? 'white' : '',"dark":"","height":"200"},on:{"click":toggle}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"max-height":"200px","src":_vm.getimagen('flor.svg')}},[_c('v-card-title',[_vm._v("Primavera")])],1),_c('v-scroll-y-transition',[(active)?_c('div',{staticClass:"display-3 flex-grow-1 text-center"},[_vm._v("P")]):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{class:("d-flex align-center " + (active ? 'm-gradient-primary' : '')),attrs:{"color":active ? 'white' : '',"dark":"","height":"200"},on:{"click":toggle}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"max-height":"200px","src":_vm.getimagen('soleado.svg')}},[_c('v-card-title',[_vm._v("Verano")])],1),_c('v-scroll-y-transition',[(active)?_c('div',{staticClass:"display-3 flex-grow-1 text-center"},[_vm._v("V")]):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{class:("d-flex align-center " + (active ? 'm-gradient-primary' : '')),attrs:{"color":active ? 'white' : '',"dark":"","height":"200"},on:{"click":toggle}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"max-height":"200px","src":_vm.getimagen('hoja-de-arce.svg')}},[_c('v-card-title',[_vm._v("Otoño")])],1),_c('v-scroll-y-transition',[(active)?_c('div',{staticClass:"display-3 flex-grow-1 text-center"},[_vm._v("O")]):_vm._e()])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }